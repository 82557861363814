/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:8e12fd29-b91e-475f-bcbf-5d36d7c6c503",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_tRXe8qxP3",
    "aws_user_pools_web_client_id": "3bigr0hmt7l35dnu8b00cke4ap",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://c5nchnehcjebleea3j4bckqqii.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
